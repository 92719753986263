body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
#range{
  position: absolute;
  top: 50%;
  left: -200px;
  width: 500px;
  height: auto;
  /* transform-origin: 0px 0px; */
  transform: rotate(90deg);
  /* display: none; */
}
#range input {
  width: 100%;
}
.bottom-widget--container{
  margin: auto;
  position: absolute;
  left: 0; right: 0;
}
.mapbox-attribution-container{
  display: none !important;
}

/* range slider */
.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 25px;
  background: #2795ad;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 40px;
  background: #6a7485;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 40px;
  background: #6a7485;
  cursor: pointer;
}
/* range slider */
.close-button {
  color: white;
  padding:2px 8px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 100%;
  color: #2795ad; 
  background-color: #29323c;
  border: 2px solid #2795ad;
}